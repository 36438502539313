<template>
  <base-section
    id="slogan"
    class="secondary py-13"
  >
    <v-container class="fill-height px-4 py-0">
      <v-responsive
        class="d-flex align-center mx-auto white--text"
        height="100%"
        max-width="1000"
        width="100%"
      >
        <base-subheading
          size="text-h4"
          weight="regular"
          title="저희가 제공하는 솔루션 및 서비스에 대해 문의주시면 상세하게 안내해 드리겠습니다."
          class="mx-auto text-uppercase text-center"
          mobile-size="text-h6"
        />
        <div
          :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
          class="d-flex flex-wrap"
        >
          <base-btn
            color="primary"
            dark
            class="mx-auto"
            @click="fnGoToContact"
          >
            문의하기
          </base-btn>
        </div>
      </v-responsive>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionSlogan',
    methods: {
      fnGoToContact () {
        this.$router.push('/contact')
      },
    },
  }
</script>
